<template>
  <div>
    <b-card>
      <h4>
          {{$t("Labels.UserInformation")}}
      </h4>
      <b-form
        class=""
        @submit.prevent
      >
      <validation-observer ref="userValidation">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.Name')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="user.first_name"
                :state="errors.length > 0 ? false:null"
                name="name"
                :placeholder="$t('Labels.Name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.LastName')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="lastName"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="user.last_name"
                :state="errors.length > 0 ? false:null"
                name="name"
                :placeholder="$t('Labels.LastName')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.Phone')"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required|digits:10"
            >
              <b-form-input
                id="phone"
                v-model="user.phone_number"
                :state="errors.length > 0 ? false:null"
                name="phone"
                :placeholder="$t('Labels.Phone')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="user.email"
                :state="errors.length > 0 ? false:null"
                name="email"
                :placeholder="$t('Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <h4>
          {{$t("Labels.TrackerInfo")}}
      </h4>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group :label="$tc('Labels.SelectTracker')" label-for="device">
            <v-select :disabled="validated == 1" input-id="tracker" :options="trackers" :clearable="false" v-model="selectedTracker" :placeholder="$t('Labels.Search') + ' ' + $t('Labels.Trackers')"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Labels.Serie')" label-for="serie">
            <b-form-input disabled id="serie" v-model="selectedTracker.serie" name="serie" :placeholder="$t('Labels.Serie')"/>
          </b-form-group>
        </b-col>
      </b-row>
      <h4>
          {{$t("Labels.ChangePassword")}}
      </h4>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Password')"
            label-for="password"
          >
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="password"
            >
              <b-form-input
                id="password"
                v-model="user.password"
                :state="errors.length > 0 ? false:null"
                name="password"
                :placeholder="$t('Password')"
                type="password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.ConfirmPassword')"
            label-for="confirmPassword"
          >
            <validation-provider
              #default="{ errors }"
              name="confirmPassword"
              rules="confirmed:password"
            >
              <b-form-input
                id="confirmPassword"
                v-model="user.confirmPassword"
                :state="errors.length > 0 ? false:null"
                name="confirmPassword"
                :placeholder="$t('Labels.ConfirmPassword')"
                type="password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" offset-md="6" class="d-flex justify-content-end">
          <b-col cols="6">
            <b-button type="submit" variant="primary" block @click="validationForm" class="mt-2">{{$t("Labels.Save")}}</b-button>
            </b-col>
          &nbsp;
          <b-col cols="6">
            <b-button type="reset" block variant="danger" @click="cancel()" class="mt-2" >{{$t("Labels.Cancel")}}</b-button>
          </b-col>
        </b-col>
      </b-row>
      </validation-observer>
      </b-form>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'
import { empty } from '@/libs/tools/helpers'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      user: {},
      uuid: this.$route.params.uuid,
      required,
      trackers: [],
      selectedTracker: {},
      validated: 0
    }
  },
  methods: {
    cancel () {
      this.user = {}
      this.$router.push('/users')
    },
    async add () {
      const params = {
        url: `tracker_users/users/${this.uuid}`,
        method: 'PUT',
        params: {
          email: this.user.email,
          phone_number: this.user.phone_number,
          first_name: this.user.first_name,
          last_name: this.user.last_name
        }
      }
      if (this.user.password) {
        Object.assign(params.params, {
          password: btoa(this.user.password),
          repassword: btoa(this.user.confirmPassword)
        })
      }
      if (this.selectedTracker.value) {
        Object.assign(params.params, {
          tracker_uuid: this.selectedTracker.value
        })
      }
      await request(params).then(response => {
        this.$refs.userValidation.reset()
        this.user = {}
        this.$router.push('/users')
      })
    },
    validationForm () {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    },
    async getUser () {
      const params = {
        url: `tracker_users/users/${this.uuid}`
      }
      await request(params).then(response => {
        this.user = response.data.data
        if (!empty(response.data.data.tracker_dual_data)) {
          this.validated = 1
          this.selectedTracker = {
            title: response.data.data.tracker_dual_data[0].name,
            value: response.data.data.tracker_dual_data[0].uuid,
            label: response.data.data.tracker_dual_data[0].name,
            serie: response.data.data.tracker_dual_data[0].serie
          }
        }
      }).catch(() => {
        this.trackers = []
      })
    },
    async getTrackers () {
      const params = {
        url: 'tracker/list?unassigned=2'
      }
      await request(params).then(response => {
        /* this.trackers = response.data.data */
        for (const item of response.data.data) {
          this.trackers.push({
            title: item.name,
            value: item.uuid,
            label: item.name,
            serie: item.serie
          })
        }
      }).catch(() => {
        this.trackers = []
      })
    }
  },
  created () {
    this.getTrackers()
    this.getUser()
  }
}
</script>
