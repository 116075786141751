var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("Labels.UserInformation"))+" ")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"userValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.Name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":_vm.$t('Labels.Name')},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.LastName'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":_vm.$t('Labels.LastName')},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.Phone'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone","placeholder":_vm.$t('Labels.Phone')},model:{value:(_vm.user.phone_number),callback:function ($$v) {_vm.$set(_vm.user, "phone_number", $$v)},expression:"user.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h4',[_vm._v(" "+_vm._s(_vm.$t("Labels.TrackerInfo"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$tc('Labels.SelectTracker'),"label-for":"device"}},[_c('v-select',{attrs:{"disabled":_vm.validated == 1,"input-id":"tracker","options":_vm.trackers,"clearable":false,"placeholder":_vm.$t('Labels.Search') + ' ' + _vm.$t('Labels.Trackers')},model:{value:(_vm.selectedTracker),callback:function ($$v) {_vm.selectedTracker=$$v},expression:"selectedTracker"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.Serie'),"label-for":"serie"}},[_c('b-form-input',{attrs:{"disabled":"","id":"serie","name":"serie","placeholder":_vm.$t('Labels.Serie')},model:{value:(_vm.selectedTracker.serie),callback:function ($$v) {_vm.$set(_vm.selectedTracker, "serie", $$v)},expression:"selectedTracker.serie"}})],1)],1)],1),_c('h4',[_vm._v(" "+_vm._s(_vm.$t("Labels.ChangePassword"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false:null,"name":"password","placeholder":_vm.$t('Password'),"type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.ConfirmPassword'),"label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"name":"confirmPassword","rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmPassword","state":errors.length > 0 ? false:null,"name":"confirmPassword","placeholder":_vm.$t('Labels.ConfirmPassword'),"type":"password"},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mt-2",attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(_vm._s(_vm.$t("Labels.Save")))])],1),_vm._v("   "),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mt-2",attrs:{"type":"reset","block":"","variant":"danger"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("Labels.Cancel")))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }